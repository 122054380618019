import React, { Component } from "react";
import axios from "axios";
import Spinner from "components/spinner/Spinner";
import { renderAttribute } from "functions/renderAttribute";
import moment from "moment";
import NavigationCircleLeft from "components/navigation/circle/NavigationCircleLeft";
import { isMobile } from "react-device-detect";

export default class UltimaUE extends Component {
  state = {
    loading: true,
    width: "90vw",
  };

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      const headers = {
        headers: { "Maestrale-Cache-Control": 1800 },
      };
      let document = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/Document",
        {
          documentId: id,
          "documentValueDefs": [
                {
                    "code":"RISORSA"
                },
                {
                    "code":"TESTO"
                },
                {
                    "code":"TITOLO"
                }
            ]
        },
        headers
      );
      document = document?.data?.data?.document;
      let headerImg = await axios.post(
        "https://api.utilitalia.it/DMS/Files/File",
        {
          fileId: renderAttribute(document?.values, "RISORSA")?.id,
        }
      );
      headerImg = headerImg?.data?.data?.data;
      this.setState({
        headerImg,
      });
      this.setState({ document });
    } catch (err) {
      console.log("Error CDM ultime-single-page", err);
    }
    this.setState({ loading: false });
  }

  goBack = () => {
    // window.location.href = "/ultime";
    this.props.history.push("/ultime-da-europa");
  };

  resizeFrame = () => {
    if (isMobile) {
      this.setState({
        width: document.getElementById("ultima-content").clientWidth - 36,
      });
    }
  };

  renderHtml = (html) => {
    html = renderAttribute(html, "TESTO");
    return (
      html &&
      html
        .split('<img src="cid:newsletter-header.jpg" />')
        .join("")
        .split("width: 700px;")
        .join(isMobile ? "" : "width: 60vh; margin: 0px 20vh;")
        .split('<p style="')
        .join(
          isMobile
            ? `<p style="width:${
                isNaN(this.state.width)
                  ? this.state.width
                  : this.state.width + "px"
              };`
            : '<p style="'
        )
        .split("<tr>")
        .join(
          isMobile
            ? '<tr style="display: flex; flex-direction: column; align-items: center">'
            : "<tr>"
        )
        .split('<td style="')
        .join(isMobile ? '<td style="padding: 8px 0;' : '<td style="')
        .split("text-align: justify;")
        .join(
          isMobile
            ? "text-align: left;font-size: 14px;line-height:18px;"
            : "text-align: justify;"
        )
        .split('<a style="')
        .join(
          isMobile
            ? '<a style="font-size: 14px;line-height:18px;'
            : '<a style="'
        )
        .split('<span style="')
        .join(
          isMobile
            ? '<span style="font-size: 14px;line-height:18px;'
            : '<span style="'
        )
        .split('<span face="Calibri"')
        .join(
          isMobile
            ? `<span face="Calibri" style="font-size: 14px;line-height:18px; width:${
                isNaN(this.state.width)
                  ? this.state.width
                  : this.state.width + "px"
              };"`
            : '<span face="Calibri"'
        )
        .split('style="margin: 0cm 0cm 0pt;')
        .join(
          isMobile
            ? `style="width:${
                isNaN(this.state.width)
                  ? this.state.width
                  : this.state.width + "px"
              }; margin: 0cm 0cm 0pt;`
            : 'style="margin: 0cm 0cm 0pt;'
        )
        .split('<td colspan="2"')
        .join(
          isMobile
            ? `<td colspan="2" style="display: flex;flex-direction: column;width: ${
                isNaN(this.state.width)
                  ? this.state.width
                  : this.state.width + "px"
              };overflow: hidden;"`
            : '<td colspan="2"'
        )
        .split('<img style="border: none;')
        .join(
          isMobile
            ? `<img style="width: 24px;border: none;`
            : '<img style="border: none;'
        )
        .split(
          `<p style="width:${
            isNaN(this.state.width) ? this.state.width : this.state.width + "px"
          };font-family: arial; font-size: 10pt; color: #000000;"`
        )
        .join(
          isMobile
            ? `<p style="font-family: arial; font-size: 10pt; color: #000000;"`
            : `<p style="width:${
                isNaN(this.state.width)
                  ? this.state.width
                  : this.state.width + "px"
              };font-family: arial; font-size: 10pt; color: #000000;"`
        )
        .split('src="https://api.utilitalia.it')
        .join('src="https://cms.utilitalia.it')
    );
  };

  render() {
    return (
      <div id="ultima">
        <div className="container ">
          
            

          {!this.state.document ? (
            <Spinner />
          ) : (
          <>
          <div
            className="pad-mobile"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
          <div style={{ flex: 1 }} onClick={this.goBack}>
              <NavigationCircleLeft />
              {/* <span className="general">Torna a Ultime</span> */}
            </div>
            <h1
              style={{ flex: 5, textAlign: "center", marginBottom: 0 }}
              className="general"
                dangerouslySetInnerHTML={{
                      __html: renderAttribute(
                        this.state.document.values,
                        "TITOLO"
                      )}}
            />
            <div style={{ flex: 1 }}></div>
          </div>
            <div className="ultima-container">
              <div id="ultima-content" className="ultima">
                {this.state.headerImg && (
                  <img
                    style={{ width: "100%" }}
                    src={`data:image/jpeg;base64, ${this.state.headerImg}`}
                    alt=""
                  />
                )}
                {!isMobile ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: renderAttribute(
                        this.state.document.values,
                        "TESTO"
                      )
                        ?.replace('<img src="cid:newsletter-header.jpg" />', "")
                        ?.split('src="https://api.utilitalia.it')
                        ?.join('src="https://cms.utilitalia.it'),
                    }}
                  />
                ) : (
                  // <iframe
                  //   title="ultime-iframe"
                  //   width={"100%"}
                  //   height={`${window.innerHeight / 2}px`}
                  //   // scrolling="no"
                  //   frameBorder="0"
                  //   onLoad={this.resizeFrame}
                  //   // onLoadSuccess={this.resizeFrame}
                  //   srcDoc={this.renderHtml(this.state.document.values)}
                  // />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.renderHtml(this.state.document.values),
                    }}
                  />
                )}
              </div>
            </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
