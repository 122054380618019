import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
// import { Helmet } from "react-helmet-async";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "font/stylesheet.css";
import "App.scss";
import "placeholder-loading/dist/css/placeholder-loading.min.css";
import SwiperCore, { Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import Logo from "img/logo/simple-logo.jpg";
import format from "functions/formatAddress";
import Navbar from "components/navbar/Navbar";
import Footer from "components/footer/Footer";
//import Home from "pages/home/Home";
import HomeNew from "pages/home/HomeNew";
import HomeNew2 from "pages/home/HomeNew2";
import Login from "pages/login/Login";
import LoginDemo from "pages/login/LoginDemo";
import ChangePassword from "pages/login/ChangePassword";
import CategoryPage from "pages/category-page/CategoryPage";
import SubCategoryPage from "pages/sub-category-page/SubCategoryPage";
import GeneralList from "pages/general-list/GeneralList";
import SingleDocument from "pages/single-document/SingleDocument";
import SimplePage from "pages/simple-page/SimplePage";
import AnswersPage from "pages/answers-page/AnswersPage";
import ContattiPage from "pages/contatti-page/ContattiPage";
import AmbienteQuesiti from "pages/settori-aree/ambiente/AmbienteQuesiti";
import LavoroQuesiti from "pages/settori-aree/lavoro-relazioni-industriali/LavoroQuesiti";
import GiurisprudenzaList from "pages/settori-aree/lavoro-relazioni-industriali/GiurisprudenzaList";
import GiurisprudenzaDocument from "pages/settori-aree/lavoro-relazioni-industriali/GiurisprudenzaDocument";
import ChiSiamoUtilitalia from "pages/chi-siamo/utilitalia/ChiSiamoUtilitalia";
import ChiSiamoStatuto from "pages/chi-siamo/statuto/ChiSiamoStatuto";
import ChiSiamoCodiceEtico from "pages/chi-siamo/codice-etico/ChiSiamoCodiceEtico";
import ChiSiamoStruttura from "pages/chi-siamo/struttura/ChiSiamoStruttura";
import ChiSiamoStrutturaOld from "pages/chi-siamo/struttura-old/ChiSiamoStruttura";
import ChiSiamoOrgani from "pages/chi-siamo/organi/ChiSiamoOrgani";
import ChiSiamoPartner from "pages/chi-siamo/partner/ChiSiamoPartner";
import ChiSiamoBrochure from "pages/chi-siamo/brochure/ChiSiamoBrochure";
import Circolari from "pages/circolari/Circolari";
import CircolariSection from "pages/circolari/CircolariSezioni";
import Circolare from "pages/circolari/Circolare";
import Associate from "pages/associate/Associate";
import NewsMediaUltimeNotizie from "pages/news-media/ultime-notizie/NewsMediaUltimeNotizie";
import UltimeList from "pages/news-media/ultime-list/ultime-list";
import UltimeUE from "pages/news-media/ultime-list/ultime-eu";
import Ultima from "pages/news-media/ultime-list/ultime-single-page";
import UltimaUE from "pages/news-media/ultime-list/ultima-single-page-ue";
import Notizia from "pages/news-media/notizia/Notizia";
import NewsMediaRassegnaStampa from "pages/news-media/rassegna-stampa/NewsMediaRassegnaStampa";
import NewsMediaPressKit from "pages/news-media/press-kit/NewsMediaPressKit";
import NewsMediaPressKitComunicatiStampa from "pages/news-media/press-kit/NewsMediaPressKitComunicatiStampa";
import NewsMediaPressKitVideo from "pages/news-media/press-kit/NewsMediaPressKitVideo";
import CalendarioEventi from "pages/calendario-eventi/CalendarioEventi";
import ArchivioEventi from "pages/calendario-eventi/ArchivioEventi";
import Evento from "pages/calendario-eventi/Evento";
import Atti from "pages/atti/Atti";
import Atto from "pages/atti/Atto";
import Pdf from "components/pdf-page/PdfPages";
import Contatti from "pages/contatti/Contatti";
import LegalPage from "pages/legal-page/LegalPage";
import ErrorPage from "pages/error-page/Error";
import Video from "pages/news-media/video/Video";
import SearchResults from "pages/search-results/SearchResults";
import BannersPage from "pages/banners-page/BannersPage";
// import MaiaTaqa from "pages/settori-aree/direzione-generale/MaiaTaqa";
// import Transizione from "pages/settori-aree/direzione-generale/Transizione";
// import TaskForce from "pages/settori-aree/direzione-generale/TaskForceSud";
// import Europa from "pages/settori-aree/direzione-generale/Europa";
// import Maintenance from "pages/maintenance-page/Maintenance";
import CookieBanner from "components/cookie-banner/CookieBanner";
import { deleteCookie, getCookie, setCookie } from "functions/cookieFuncs";
import ChiSiamoPresentazione from "pages/chi-siamo/presentazione/Presentazione";

SwiperCore.use([Navigation, Autoplay, EffectFade]);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return null;
};

const MyHelmet = () => {
  const [path, setPath] = useState(format());
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { pathname } = useLocation();

  useEffect(() => {
    setPath(format());
  }, [pathname]);

  useEffect(() => {
    const trackingCookies = getCookie("tracking");
    if (!!trackingCookies && trackingCookies !== "false") {
      if (!isFirstLoad) {
        setTimeout(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "Pageview",
            page_title: document.title ? document.title : "",
            page_URL: window.location.href ? window.location.href : "",
            page_path: window.location.pathname ? window.location.pathname : "",
            page_hash: window.location.hash ? window.location.hash : "",
          });
        }, 500);
      } else {
        setIsFirstLoad(false);
      }
    }
  }, [pathname]);

  return (
    <Helmet>
      {/* <title>Utilitalia{path}</title> */}
      <link rel="icon" href={Logo} />
    </Helmet>
  );
};

const App = () => {
  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const handleAcceptAllCookies = (cookies) => {
    setIsBannerOpen(false);
    setCookie("cookie_setted", "true");
    cookies?.map((cookie) => setCookie(cookie.target, "true"));
    window.location.reload();
  };

  const handleRejectAllCookies = (cookies) => {
    setIsBannerOpen(false);
    setCookie("cookie_setted", "true");
    cookies?.map((cookie) => setCookie(cookie.target, "false"));
    deleteCookie("_ga");
    deleteCookie("_gid");
    window.location.reload();
  };

  const handleAcceptCustomCookies = (cookies) => {
    deleteCookie("_ga");
    deleteCookie("_gid");
    setIsBannerOpen(false);
    setCookie("cookie_setted", "true");
    for (const [target, value] of Object.entries(cookies)) {
      setCookie(target, value);
    }
    window.location.reload();
  };

  useEffect(() => {
    if (getCookie("cookie_setted") === "") {
      setIsBannerOpen(true);
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <Switch>
          {/*<Route exact path="/login" component={Login} />*/}
            <Route exact path="/login" component={LoginDemo} />
            <Route exact path="/cambia-password" component={ChangePassword} />
          <Route>
            <div className="App">
              <Navbar />
              <MyHelmet />

              <Switch>
                <Route exact path="/" component={HomeNew} />
                <Route exact path="/home-new" component={HomeNew2} />
                <Route
                  exact
                  path="/risultati-ricerca"
                  component={SearchResults}
                />
                <Route
                  exact
                  path="/area-lavoro-relazioni-industriali/giurisprudenza/list"
                  component={GiurisprudenzaList}
                />
                <Route
                  exact
                  path="/area-lavoro-relazioni-industriali/giurisprudenza/list/:id"
                  component={GiurisprudenzaDocument}
                />
                {/* <Route
                  exact
                  path="/direzione-generale/pianificazione-strategia-sostenibilita/cooperazione/maia-taqa"
                  component={MaiaTaqa}
                />

                <Route
                  exact
                  path="/direzione-generale/pianificazione-strategia-sostenibilita/sostenibilita/transizione"
                  component={Transizione}
                />
                <Route
                  exact
                  path="/direzione-generale/pianificazione-strategia-sostenibilita/sud/task-force"
                  component={TaskForce}
                />
                <Route
                  exact
                  path="/direzione-generale/europa"
                  component={Europa}
                /> */}
                <Route
                  exact
                  path={[
                    "/:sector/home",
                    "/:sector/:subsector/home",
                    "/:sector/:subsector/:subsector/home",
                  ]}
                  component={CategoryPage}
                />
                <Route
                  exact
                  path={[
                    "/:sector/:subsector/category-home",
                    "/:sector/:subsector/:subsubsector/category-home",
                  ]}
                  component={SubCategoryPage}
                />
                <Route
                  exact
                  path={[
                    "/:list/list",
                    "/:sector/:list/list",
                    "/:sector/:subsector/:list/list",
                    "/:sector/:subsector/:subsubsector/:list/list",
                  ]}
                  component={GeneralList}
                />
                <Route
                  exact
                  path={[
                    ":list/list/:id",
                    "/:sector/:list/list/:id",
                    "/:sector/:subsector/:list/list/:id",
                    "/:sector/:subsector/:subsubsector/:list/list/:id",
                    "/circolare/archivio/:id",
                  ]}
                  component={SingleDocument}
                />
                <Route
                  exact
                  path={[
                    "/:sector/:subsector/page",
                    "/:sector/:subsector/:subsubsector/:test/page",
                  ]}
                  component={SimplePage}
                />
                <Route
                  exact
                  path={["/:sector/contatti", "/:sector/:subsector/contatti"]}
                  component={ContattiPage}
                />
                <Route
                  exact
                  path="/:sector/progetti-di-comunicazione"
                  component={BannersPage}
                />
                <Route
                  exact
                  path="/ambiente/quesiti"
                  component={AmbienteQuesiti}
                />
                <Route
                  exact
                  path="/area-lavoro-relazioni-industriali/quesiti"
                  component={LavoroQuesiti}
                />
                <Route strict path="/legal/:id" component={LegalPage} />
                <Route
                  exact
                  path="/chi-siamo/utilitalia"
                  component={ChiSiamoUtilitalia}
                />
                <Route
                  exact
                  path={["/:sector/circolari", "/:sector/fiscale-e-tributario"]}
                  component={CircolariSection}
                />
                <Route
                  exact
                  path="/chi-siamo/statuto"
                  component={ChiSiamoStatuto}
                />
                <Route
                  exact
                  path="/chi-siamo/codice-etico"
                  component={ChiSiamoCodiceEtico}
                />
                <Route
                  exact
                  path="/chi-siamo/struttura"
                  component={ChiSiamoStruttura}
                />
                <Route
                  exact
                  path="/chi-siamo/struttura-old"
                  component={ChiSiamoStrutturaOld}
                />
                <Route
                  exact
                  path="/chi-siamo/struttura/direzione-generale"
                  component={ChiSiamoStruttura}
                />
                <Route
                  exact
                  path="/chi-siamo/organi"
                  component={ChiSiamoOrgani}
                />
                <Route
                  exact
                  path="/chi-siamo/partner"
                  component={ChiSiamoPartner}
                />
                <Route
                  exact
                  path="/chi-siamo/brochure"
                  component={ChiSiamoBrochure}
                />
                <Route
                  exact
                  path="/chi-siamo/presentazione"
                  component={ChiSiamoPresentazione}
                />
                <Route exact path="/circolari" component={Circolari} />
                <Route exact path="/circolare/:id" component={Circolare} />
                <Route exact path="/associate" component={Associate} />
                <Route exact path="/ultime" component={UltimeList} />
                <Route exact path="/ultime-da-europa" component={UltimeUE} />
                <Route
                  exact
                  path="/news-media/ultime-notizie"
                  component={NewsMediaUltimeNotizie}
                />
                <Route exact path="/ultime/:id" component={Ultima} />
                <Route exact path="/ultime-da-europa/:id" component={UltimaUE} />
                <Route exact path="/notizia/:id" component={Notizia} />
                <Route exact path="/video/:id" component={Video} />
                <Route
                  exact
                  path="/news-media/rassegna-stampa"
                  component={NewsMediaRassegnaStampa}
                />
                <Route
                  exact
                  path="/news-media/press-kit"
                  component={NewsMediaPressKit}
                />
                <Route
                  exact
                  path="/news-media/press-kit/comunicati-stampa"
                  component={NewsMediaPressKitComunicatiStampa}
                />
                <Route
                  exact
                  path="/news-media/press-kit/video"
                  component={NewsMediaPressKitVideo}
                />
                <Route
                  exact
                  path="/calendario-eventi"
                  component={CalendarioEventi}
                />
                <Route
                  exact
                  path="/archivio-eventi"
                  component={ArchivioEventi}
                />
                <Route exact path="/evento/:id" component={Evento} />
                <Route exact path="/atti" component={Atti} />
                <Route exact path="/atto/:id" component={Atto} />
                <Route exact path="/pdf/:id" component={Pdf} />
                <Route
                  exact
                  path="/ambiente/csr-sostenibilita/misurarsi-per-migliorare/:id"
                  component={SimplePage}
                />
                <Route
                  exact
                  path="/direzione-generale/csr-sostenibilita/misurarsi-per-migliorare/:id"
                  component={SimplePage}
                />
                <Route
                  exact
                  path={["/quesito/:id", "/:sector/quesito/:id"]}
                  component={AnswersPage}
                />
                <Route exact path="/contatti" component={Contatti} />
                {/* <Route exact path="/pagina-non-trovata" component={ErrorPage} />
              <Redirect to="/pagina-non-trovata" /> */}
                {/* <Route
                  exact
                  path="/pagina-in-manutenzione"
                  component={Maintenance}
                /> */}
                <Route component={ErrorPage} />
              </Switch>
              <Footer />
              {isBannerOpen && (
                <CookieBanner
                  handleAcceptAllCookies={handleAcceptAllCookies}
                  handleRejectAllCookies={handleRejectAllCookies}
                  handleAcceptCustomCookies={handleAcceptCustomCookies}
                />
              )}
            </div>
          </Route>
        </Switch>
      </Router>
    </HelmetProvider>
  );
};

export default App;
