import React, { Component } from "react";
import axios from "axios";
import "../rassegna-stampa/NewsMediaRassegnaStampa.scss";
import "./ultime-list.scss";
import moment from "moment";
import Filter from "components/filter/Filter";
import { Link } from "react-router-dom";
import Spinner from "components/spinner/Spinner";
import NavigationCircle from "components/navigation/circle/NavigationCircle";

const getId = element => {
    return typeof element?.talkingId !== 'undefined' && element?.talkingId.trim() !== '' && element?.talkingId !== null && element?.talkingId !== false  ? element.talkingId : element.id;
}

const groupByMonths = (documents) => {
  return documents.reduce((acc, curr) => {
    const month = moment(
      curr.values.find((att) => att.code === "DATA").value
    ).format("MMMM YYYY");
    return {
      ...acc,
      [month]: [...(acc[month] || []), curr],
    };
  }, {});
};

const toArray = (obj) => {
  return Object.entries(obj).map((att) => {
    return { month: att[0], data: att[1] };
  });
};

const formatData = (documents) => {
  return toArray(groupByMonths(documents));
};

const activeYear = moment().year();

const years = [];

for (let i = 2015; i <= activeYear; i++) {
  years.unshift(i);
}

export default class UltimeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ultime: [],
      ultimeFrom: 1,
      loadingUltime: true,
      active: this.initialStateFilter,
      startYear: 2017,
      years,
    };
  }

  initialStateFilter = {
    year: activeYear,
  };

  async componentDidMount() {
    try {
      const response = await this.fetchUltime();
      const ultime = response?.data?.data?.documents;
      if (ultime) {
        this.setState({
          ultime,
          loadingUltime: false,
        });
      }
    } catch (err) {
      console.log("Error CDM ultime-list", err);
    }
  }

  async componentDidUpdate(oldProps, oldState) {
    if (
      this.state.ultimeFrom !== oldState.ultimeFrom ||
      this.state.active.year !== oldState.active.year
    ) {
      window.scrollTo(0, 0);
      this.setState({ loadingUltime: true });
      try {
        const response = await this.fetchUltime();
        const ultime = response?.data?.data?.documents;
        if (ultime && ultime.length === 0) {
          this.setState({ ultimeFrom: 1 });
        }
        this.setState({
          ultime: ultime || [],
        });
      } catch (err) {
        console.log("Error CDU ultime-list", err);
      }
      this.setState({ loadingUltime: false });
    }
  }

  handleFilter = (year) => {
    this.setState({ year });
  };

  fetchUltime = async () => {
    const headers = {
      headers: { "Maestrale-Cache-Control": 300 },
    };
    return await axios.post(
      "https://api.utilitalia.it/DMS/Documents/List",
      {
        documentClasses: ["NEWSLETTER"],
        //headingPath: "\\COM",
        heading: "\\COM\\NEWSLETTER",
        folder: this.state.active.year,
        documentValueDefs: [
          {
            code: "DATA",
          },
        ],
        pageStart: this.state.ultimeFrom,
        pageSize: 20,
        documentValueOrders: [
          {
            code: "DATA",
            type: "5",
            direction: "desc",
          },
        ],
      },
      headers
    );
  };

  handleUltimeNavigation = (delta) => {
    this.setState({ ultimeFrom: this.state.ultimeFrom + delta * 20 });
  };

  handleChange = (value, target) => {
    this.setState({ active: { ...this.state.active, [target]: value } });
  };

  handleSubmit = () => {
    this.setState({ loadingUltime: true });
    this.fetchUltime().then((response) => {
      const ultime = response?.data?.data?.documents;
      if (ultime) {
        this.setState({
          ultime,
          loadingUltime: false,
        });
      }
    });
  };

  handleReset = () => {
    this.setState({
      active: this.initialStateFilter,
    });
  };

  render() {
    const filters = [
      {
        name: "year",
        text: "Seleziona l'anno",
        values: this.state.years,
        value: this.state.active.year,
      },
    ];

    const formattedUltime = formatData(this.state.ultime && this.state.ultime);

    return (
      <div id="news-media-rassegna-stampa">
        <div className="container pad-mobile">
          <h2 className="general">
            Le ultime <span>Notizie</span>
          </h2>
          <Filter
            filters={filters}
            change={this.handleChange}
            reset={this.handleReset}
            value={this.state.active}
          />
          {this.state.loadingUltime ? (
            <Spinner />
          ) : (
            formattedUltime.map((ultima, i) => (
              <div key={`ultima_${i}`}>
                <div className={"month-header"}>{ultima.month}</div>
                <div className={"list-container"}>
                  {ultima.data.map((date, i) => (
                    <div key={`data_${i}`} className={"single-date"}>
                      <div>
                        Ultime di{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {moment(
                            date.values.find((value) => value.code === "DATA")
                              .value
                          ).format("dddd DD")}
                        </span>
                      </div>
                      <Link to={"/ultime/" + getId(date)} className="btn ">
                        Leggi
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
          {!this.state.loadingUltime && this.state.ultime.length === 0 && (
            <div style={{ backgroundColor: "white", padding: 24 }}>
              Nessun risultato
            </div>
          )}
          {this.state.ultime.length > 0 && (
            <NavigationCircle
              function={this.handleUltimeNavigation}
              disabled={this.state.ultimeFrom === 1}
              nextDisabled={this.state.ultime.length < 20}
            />
          )}
        </div>
      </div>
    );
  }
}
