import React, { Component} from "react";
import "./Login.scss";
import axios from "axios";
import { IoCloseOutline as Close } from "react-icons/io5";
import { IoEye as Eye } from "react-icons/io5";
import { IoEyeOff as EyeOff } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import Logo from "img/logo/simple-logo.jpg";

class LoginDemo extends Component {
  state = { isPasswordHidden: true };
  goBack = () => {
    if (/* !this.props.location.state */ this.props.history.action !== "PUSH") {
      this.props.history.push("/");
    } else if (this.props.location.state) {
      this.props.history.go(-2);
    } else {
      // console.log("go back");
      this.props.history.goBack();
    }
  };

  _login = async () => {
    const { mail, password } = this.state;
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/CMS/Account/Login",
        {
          username: mail,
          password,
        }
      );
      if (!response?.data?.data?.sessionId) {
        this.setState({
          loginError: true,
          showPopup: false,
          alertMessage: (typeof response?.data?.data?.alertMessage !== 'undefined' && response?.data?.data?.alertMessage !== null && response?.data?.data?.alertMessage !== false && response?.data?.data?.alertMessage !== '') ? response?.data?.data?.alertMessage : response?.data?.errorMessage,
          errorType: response?.data?.data?.resultType?.toLowerCase(),
          errorMessage: response?.data?.data?.resultType
            .split(/(?=[A-Z])/)
            .join(" "),
        });
      } else {
        sessionStorage.setItem("sessionId", response?.data?.data?.sessionId);
        sessionStorage.setItem('uniqueCode', response?.data?.data?.uniqueCode);
        sessionStorage.setItem('fullName', response?.data?.data?.fullName);
        sessionStorage.setItem('alertMessage', response?.data?.data?.alertMessage );
        if( response?.data?.data?.alertMessage !== null && typeof response?.data?.data?.alertMessage !== 'undefined' && response?.data?.data?.alertMessage !== false && response?.data?.data?.alertMessage !== '' ) {
            this.setState({
                showPopup: true,
                alertMessage: response?.data?.data?.alertMessage
            })
        } else {
            this.props.history.goBack();
            
        }
      }
    } catch (err) {
      this.setState({
        loginError: true,
        showPopup: false,
      });
    }
  };

  _resetPassword = async () => {
    const { mail, password, newPassword } = this.state;
    try {
      const response = await axios.post(
        "https://api.utilitalia.it/CMS/Account/LoginAndChangePassword",
        {
          username: mail,
          password,
          newPassword,
        }
      );
      console.log( response?.data );
      if (!response?.data?.data?.sessionId) {
        this.setState({
            showPopup: false,
          loginError: true,
          errorType: response?.data?.data?.resultType?.toLowerCase(),
          errorMessage: response?.data?.data?.resultType
            .split(/(?=[A-Z])/)
            .join(" "),
            alertMessage: response?.data?.data?.alertMessage
        });
      } else {

        sessionStorage.setItem("sessionId", response?.data?.data?.sessionId);
        sessionStorage.setItem('uniqueCode', response?.data?.data?.uniqueCode);
        sessionStorage.setItem('fullName', response?.data?.data?.fullName);
        sessionStorage.setItem('alertMessage', response?.data?.data?.alertMessage );
        this.goBack();
        
        
      }
    } catch (err) {
        console.log( err );
      this.setState({
        loginError: true,
        showPopup: false,
      });
    }
  };

  _handleChangeInput = (target, value) => this.setState({ [target]: value });

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this._login(this.state.mail, this.state.password);
    }
  };

  render() {
    const passwordIsExpired = this.state.errorType
      ?.toLowerCase()
      .includes("expired");
    const passwordIsWeak = this.state.errorType?.toLowerCase().includes("weak");
    return (
        <>
        <div id="login">
        <Helmet>
          <title>Utilitalia</title>
          <link rel="icon" href={Logo} />
        </Helmet>

        <Close className="close" onClick={this.goBack} />

        <div className="content">
          <h6>Area Riservata</h6>
          <h2 className="general">
            Effettua il login <br />
            <span>per accedere all'area riservata</span>
          </h2>
          <div className="input">
            <input
              onKeyPress={this.handleKeyPress}
              onChange={(event) =>
                this._handleChangeInput("mail", event.target.value)
              }
              type="email"
              target="mail"
              placeholder="Indirizzo email/Username"
            />
            <div style={{ position: "relative" }}>
              <input
                onKeyPress={this.handleKeyPress}
                onChange={(event) =>
                  this._handleChangeInput("password", event.target.value)
                }
                type={this.state.isPasswordHidden ? "password" : "text"}
                target="password"
                id="password"
                placeholder="Password"
              />
              {this.state.isPasswordHidden ? (
                <Eye
                  className="eye"
                  onClick={() => this.setState({ isPasswordHidden: false })}
                />
              ) : (
                <EyeOff
                  className="eye"
                  onClick={() => this.setState({ isPasswordHidden: true })}
                />
              )}
            </div>
            {(passwordIsExpired || passwordIsWeak) && (
              <input
                onKeyPress={this.handleKeyPress}
                onChange={(event) =>
                  this._handleChangeInput("newPassword", event.target.value)
                }
                type="password"
                target="newPassword"
                id="newPassword"
                placeholder="Nuova Password"
              />
            )}
            {this.state.loginError && (
              <p
                className="p-alert-message"
                style={{
                  color: "crimson",
                  textTransform: "uppercase",
                  textAlign: "center",
                  fontWeight: 500,
                  paddingLeft: 30,
                  paddingRight: 30
                }}
                dangerouslySetInnerHTML={{__html:
                passwordIsWeak
                  ? "Password troppo debole, inserire una nuova password"
                  : passwordIsExpired
                  ? "Password scaduta, inserire una nuova password"
                  : (this.state.alertMessage !== null && this.state.alertMessage !== false && typeof this.state.alertMessage !== 'undefined' ? this.state.alertMessage : "Username o password errati")
                }}
              />
            )}
            <button
              className="btn big"
              value="Accedi"
              onClick={
                passwordIsExpired || passwordIsWeak
                  ? () => this._resetPassword()
                  : () => this._login()
              }
            >
              Accedi
            </button>
            <a href="https://portale.utilitalia.it/profilo/registrazione" target="_blank">Non hai ancora un account? Registrati ora</a>
            <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a>
          </div>
        </div>
        </div>

        <div style={{ opacity:this.state.showPopup ? 1 : 0, pointerEvents: this.state.showPopup ? 'auto' : 'none', background: 'rgba(0,0,0,0.9)', padding: 20, overflowX: 'hidden', overflowY: 'auto', position: 'fixed', alignContent: 'center', top: 0, left: 0, bottom: 0, right: 0, zIndex: 9999999999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{position: 'relative', zIndex: 1,width: '100%',margin:'auto',maxWidth:768}}>
            <div style={{display:'flex',justifyContent:'flex-end', width: '100%', margin: 'auto', maxWidth: 768, padding: '0 20px 20px', textTransform: 'uppercase', fontSize: 14, color: 'white', cursor: 'pointer' }}><a style={{color:'white'}} href="#" onClick={e => {e.preventDefault(); this.props.history.go(-2);}} dangerouslySetInnerHTML={{__html:'Chiudi'}}/></div>
            <div style={{background:'white', textAlign: 'center', padding: 20, width: '100%', margin: 'auto', fontSize: 20, position: 'relative', maxWidth: 768 }}>
                <p dangerouslySetInnerHTML={{__html:this.state.alertMessage}} />
            </div>
            </div>
        </div>
        </>
    );
  }
}

export default LoginDemo;
